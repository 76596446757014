
div.main{
    width: 90%;
    margin: auto;
}

div.home{
    padding-top: 100px;
}

.btn-info {
    opacity: 0.8;
}

.page-header {
    padding-top: 100px !important;
    min-height: 10vh !important;;
    max-height: 400px !important;;
}

.table-list {
    width: 100%;
    align: center;
    margin: auto;
    position: relative;
}

.list-element{
    height: 50px;
    background: radial-gradient(ellipse at bottom, #292D61 30%, #171941 80%);
    margin: 0 auto 10px;
    border: 1px solid #3d3d79;
    border-left: 6px solid #00f2c3;
}

.table-header {
    width: 100%;
    margin: 0 auto 10px;
    background-color: #2a2b59;
    width: 100%;
    font-family: arial, sans-serif;
}

/*a[href^="/"] {*/
    /*text-decoration: underline;*/
    /*color: #1d8cf8 !important;*/
/*}*/

.list-row {
    height: inherit;
    margin: auto;
    padding: 10px;
}

.semi-title{
    text-align: left;
    font-size: 1.4375rem;
}

table {
    background: radial-gradient(ellipse at bottom, #292D61 30%, #171941 80%);
    width: 100%;
    font-family: arial, sans-serif;
    border-collapse: collapse;
}

tr {
    border: 1px solid #3d3d79;
    background: radial-gradient(ellipse at bottom, #292D61 30%, #171941 80%);
    text-align: center;
    padding: 8px;
}

td {
    text-align: center;
    padding: 8px;
}

th {
    border: 1px solid #3d3d79;
    padding: 8px;
    text-align: center;
}

h1, h2, h3, body{
    color: #ffffff;
}

.text-on-back-large {
     font-size: 4em !important;

}

.search-field {
    min-width: 200px;
}

.modal.modal-primary .modal-content {
    background-color: #acbb24f7 !important;
}

.custom-header {
    background-color: #3498db; /* Change the color to your desired background color */
    color: #fff; /* Change the color of the text for better visibility */
    /* Add any other styling as needed */
  }

  .long-word-cell {
    word-break: break-all;
  }